/*
 * @Author: Jeason
 * @Date: 2021-06-22 16:41:07
 * @LastEditTime: 2021-06-22 16:41:13
 * @LastEditors: Jeason
 * @Description: 仓储管理-业务操作 共用js
 * @FilePath: \web\src\pages\wms\businessOperation\outboundBusinessMixin.js
 */
import DateRangePickerMixin from '@/mixins/dateRangePickerMixin.js';
import TableWithPagination, { TablePaginationMixin } from '@/components/common/TableWithPagination';
let InfoMixin = {
  components: {
    TableWithPagination,
  },
  mixins: [TablePaginationMixin, DateRangePickerMixin],
  data () {
    return {
      targetLists: [
      ],
      currentOrder: [{orderByProperty: 'createTime', orderByType: 'DESC'}],
    };
  },
  created () {
    this.pagination.pageSize = 10;
    this.getTargetLists();
  },
  methods: {
    initPagination () {
      this.pagination = {
        total: 0,
        currentPageAmount: 0,
        pageSize: 10,
        currentPage: 1,
      };
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.$refs.JZForm.validate(valid => {
        if (valid) {
          this.getTargetLists();
        }
      });
    },
    getTargetListsSuccessFun (jsonData) {
      let currentLists = [];
      if (jsonData.heads.code === 200) {
        if (jsonData.body.hasOwnProperty('list')) {
          currentLists = jsonData.body.list || [];
        } else {
          currentLists = jsonData.body || [];
        }
      } else {
        this.$JZShowAlert(jsonData.heads.message, 'error');
      }
      this.targetLists = currentLists || [];
      this.pagination.total = jsonData.body.total;
    },
    getCurrentParams () {
      let currentParams = {
        ...this.JZForm,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        startTime: this.JZForm.beginTime || (this.JZForm.operationTime && this.JZForm.operationTime.length ? this.JZForm.operationTime[0] : ''),
        endTime: this.JZForm.endTime || (this.JZForm.operationTime && this.JZForm.operationTime.length ? this.JZForm.operationTime[1] : ''),
        startOperationTime: this.JZForm.beginTime || (this.JZForm.operationTime && this.JZForm.operationTime.length ? this.JZForm.operationTime[0] : ''),
        endOperationTime: this.JZForm.endTime || (this.JZForm.operationTime && this.JZForm.operationTime.length ? this.JZForm.operationTime[1] : ''),
      };
      if (this.sortInfo.orderByType && this.sortInfo.orderByProperty) {
        currentParams.orderBy = [{
          orderByProperty: this.sortInfo.orderByProperty,
          orderByType: this.sortInfo.orderByType,
        }];
      }
      return currentParams;
    },
    handleDeleteSuccessFun (jsonData) {
      if (jsonData.heads.code === 200) {
        this.$JZShowAlert('删除成功！');
        this.getTargetLists();
      } else {
        this.$JZShowAlert(jsonData.heads.message, 'error');
      }
    },
    handleSortTable (column) {
      let currentOrder = this.currentOrder;
      let currentOrderLists = currentOrder.filter(item => {
        return column.prop !== item.orderByProperty;
      });
      this.currentOrder = [{
        orderByProperty: column.prop,
        orderByType: column.order === 'ascending' ? 'ASC' : 'DESC',
      }, ...currentOrderLists];
      this.getTargetLists();
    },
    isHasValue (currentParams) {
      let isHasValue = false;
      Object.keys(currentParams).forEach(key => {
        if (currentParams[key] !== '') {
          isHasValue = true;
        }
      });
      return isHasValue;
    },
    downBinaryStreamHandle ({ data, type, fileTitle }) {
      let blob = new Blob([data], { type });
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileTitle;
      document.querySelectorAll('body')[0].appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
export default InfoMixin;
