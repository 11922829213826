<template>
  <el-dialog
    title="查看员工"
    :visible.sync="isShow"
    width="600px"
    :append-to-body='true'
    :destroyOnClose='true'
    :close-on-click-modal='true'
  >
    <DetailInfo :detailInfoLists='detailInfoLists'></DetailInfo>
  </el-dialog>
</template>

<script>
import DetailInfo from '@/components/detailInfo/index';
export default {
  name: 'ViewStaff',
  components: {
    DetailInfo,
  },
  methods: {
    initPage () {
      this.isShow = true;
    },
  },
  data () {
    return {
      isShow: false,
      detailInfoLists: [
        {
          label: '员工姓名：',
          value: '',
          span: 12,
        },
        {
          label: '性别：',
          value: '',
          span: 12,
        },
        {
          label: '手机号：',
          value: '',
          span: 12,
        },
        {
          label: '邮箱：',
          value: '',
          span: 12,
        },
        {
          label: '开通时间：',
          value: '',
          span: 12,
        },
        {
          label: '使用状态：',
          value: '',
          span: 12,
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
