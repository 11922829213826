<template>
  <div class='JZContainer'>
    <content-block  title='员工授权'>
      <TableWithPagination :tableData="targetLists"
        ref="orderTable"
        :columnList="columnList"
        row-key='userId'
        :pagination.sync="pagination"
        :sortInfo.sync="updateSortInfo"
        @current-change="getTargetLists"
        @sort-change="getTargetLists"
        :leftToolbar="tableButtons"
      ></TableWithPagination>
    </content-block>
    <TransferAdministrator ref="transferAdministrator" @handleSearch='handleSearch'></TransferAdministrator>
    <AddStaff ref="addStaff" @handleSearch='handleSearch'></AddStaff>
    <ViewStaff ref="viewStaff"></ViewStaff>
  </div>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';
import TransferAdministrator from './components/transferAdministrator.vue';
import AddStaff from './components/addStaff.vue';
import {getSupplierUserList, delSupplierUser} from '@/api/userCenter';
import ViewStaff from './components/viewStaff.vue';
export default {
  name: 'Staff',
  mixins: [InfoMixin],
  components: {
    TransferAdministrator,
    AddStaff,
    ViewStaff,
  },
  methods: {
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    getTargetLists () {
      let currentParams = {
        pageFlag: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
      };
      currentParams.orderBy = [{
        orderByProperty: 'createTime',
        orderByType: 'DESC',
      }];
      getSupplierUserList(currentParams).then(res=>{
        this.targetLists = res.body.list;
        this.pagination.total = res.body.total;
      });
    },
    handleDelete (info) {
      let settings = Object.assign({}, { // 合并对象
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
      });
      this.$confirm(`确认删除员工【${info.name}】?`, '', settings).then(() => {
        // 这里调用接口
        delSupplierUser({userId: info.userId}).then(()=>{
          this.handleSearch();
        });
      });
    },
    addStaff () {
      this.$refs.addStaff.initPage();
    },
    transferAdministrator () {
      this.$refs.transferAdministrator.initPage();
    },
    handleDetail (info) {
      this.$refs.viewStaff.detailInfoLists = [
        {
          label: '员工姓名：',
          value: info.name,
          span: 12,
        },
        {
          label: '性别：',
          value: info.gender === 'M' ? '男' : info.gender === 'F' ? '女' : '',
          span: 12,
        },
        {
          label: '手机号：',
          value: info.phone,
          span: 12,
        },
        {
          label: '邮箱：',
          value: info.email,
          span: 12,
        },
        {
          label: '开通时间：',
          value: this.$options.filters['date-format'](info.createTime, 'YYYY-MM-DD HH:mm:ss'),
          span: 12,
        },
        {
          label: '使用状态：',
          value: info.deleteFlag === true ? '已删除' : info.deleteFlag === false ? '使用中' : '--',
          span: 12,
        },
      ];
      this.$refs.viewStaff.initPage();
    },
  },
  computed: {
    tableButtons () {
      return [
        {
          name: '新增员工', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: 'small', // 大小尺寸
          code: 'addStaff', // code(必填)
          icon: 'el-icon-plus', // 按钮内部icon
          permission: '', // 权限码，若为空字符串或不设置则表示不进行权限控制
          click: this.addStaff, // 点击回调事件
        },
        {
          name: '转让管理员', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: 'small', // 大小尺寸
          code: 'transferAdministrator', // code(必填)
          icon: '', // 按钮内部icon
          permission: '', // 权限码，若为空字符串或不设置则表示不进行权限控制
          click: this.transferAdministrator, // 点击回调事件
        },
      ];
    },
  },
  data () {
    return {
      targetLists: [],
      columnList: [
        {
          tooltip: true,
          label: '员工姓名',
          minWidth: '110px',
          prop: 'name',
        },
        {
          tooltip: true,
          label: '员工账号',
          minWidth: '150px',
          prop: 'phone',
        },
        {
          tooltip: true,
          label: '企业身份',
          minWidth: '110px',
          prop: 'identity',
          format: (val) => {
            return val === 'ADMIN' ? '管理员' : val === 'NORMAL' ? '普通用户' : '--';
          },
        },
        {
          tooltip: true,
          label: '开通时间',
          minWidth: '110px',
          prop: 'createTime',
          format: this.$options.filters['date-format'],
        },
        // {
        //   tooltip: true,
        //   label: '使用状态',
        //   minWidth: '110px',
        //   prop: 'deleteFlag',
        //   format: (val) => {
        //     return val === true ? '已删除' : val === false ? '使用中' : '--';
        //   },
        // },
        {
          tooltip: true,
          label: '操作',
          minWidth: '150px',
          prop: 'userId',
          render: (h, {row}) => {
            let actions = [];
            actions.push(h('span', {
              domProps: {
                className: 'blue',
              },
              on: {
                click: this.handleDetail.bind(this, row),
              },
            }, '查看'));
            if (row.identity !== 'ADMIN') {
              actions.push(h('span', {
                domProps: {
                  className: 'blue',
                },
                on: {
                  click: this.handleDelete.bind(this, row),
                },
              }, '删除'));
            }

            return h('div', actions);
          },
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
