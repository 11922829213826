<!--
 * @Author: Jeason
 * @Date: 2021-06-23 13:32:37
 * @LastEditTime: 2021-06-23 13:32:40
 * @LastEditors: Jeason
 * @Description: 展示信息
 * @FilePath: \web\src\components\detailinfo\detailInfo.vue
-->
<template>
  <el-row class="infoContainer content_block" >
    <el-col :span="detailInfo.span||6" class="detailInfo"  v-for="detailInfo in  detailInfoLists" :key="detailInfo.label" >
      <span class="labelTitle">{{detailInfo.label}}</span>
      <span class="labelContent" :title="detailInfo.value">{{[undefined,'',null].indexOf(detailInfo.value)!==-1 ? '--' : detailInfo.value}}</span>
    </el-col>
  </el-row>
</template>

<script>
export default {
  props: {
    detailInfoLists: {
      type: Array,
      required: true,
      default () {
        return [];
      },
    },
    span: {
      type: Number,
      default () {
        return 6;
      },
    },
  },
  name: 'Detailinfo',
  data () {
    return {
    };
  },
};
</script>
<style scoped lang='scss'>
.infoContainer {
  color: #7F7F7F;
  margin-bottom: 10px;
  height: auto;
  width: 100%;
  margin-bottom: 10px;
  .detailInfo{
    display: flex;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    .labelContent{
      flex: 1;
    }
    .labelTitle{
      color: #333333;
      width:100px;
      text-align: right;
    }
  }
}
</style>
